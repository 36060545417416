import React from "react";

type LetalkProps = {
	width?: number,
	height?: number
}

export default function Letalk ({width, height}: LetalkProps) {
	return <svg width={width || "84"} height={height || "97"} viewBox="0 0 84 97" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path fill-rule="evenodd" clip-rule="evenodd" d="M39.6174 0.59455C37.9302 1.57539 37.1855 2.90255 37.1855 4.92995C37.1855 6.43224 37.3109 6.80116 38.1459 7.75389C39.5478 9.35338 40.6654 9.84242 42.4374 9.63213C44.9343 9.33595 46.3432 7.92842 46.7701 5.30376C47.3656 1.64233 42.8874 -1.30601 39.6174 0.59455ZM37.7957 12.969C37.3762 13.0451 35.7286 13.3287 34.1345 13.5992C28.4866 14.5577 22.2687 17.1604 17.7383 20.4621C15.2838 22.2511 10.1933 27.2082 8.3597 29.5956C4.07829 35.1701 1.25676 41.9612 0.243535 49.1321C-0.260179 52.6963 0.0568159 60.7632 0.833285 64.1422C2.74013 72.4389 6.25026 78.7561 12.2976 84.7738C18.4974 90.9435 24.8443 94.4276 33.2439 96.2722C36.3998 96.9655 36.5945 96.9706 60.2114 96.9853L84 97L83.9094 73.159C83.8243 50.6341 83.7852 49.1596 83.2065 46.4491C81.4207 38.0891 77.7721 31.3861 71.6912 25.2942C65.6512 19.2431 58.5257 15.3252 50.6277 13.7129C47.7268 13.1206 39.5689 12.6477 37.7957 12.969ZM49.3567 26.2405C58.0684 28.362 66.2263 35.6515 69.662 44.3838C71.3465 48.6654 71.3452 48.6455 71.351 73.6147L71.3562 96.6885L65.3306 90.6815L59.3049 84.6745L48.1689 84.5256C38.1313 84.3914 36.7773 84.3129 34.4396 83.727C25.7779 81.5559 17.6422 74.2355 14.299 65.6044C12.8028 61.7425 12.3717 58.8073 12.559 53.7674C12.7531 48.5575 13.3294 46.145 15.3751 41.9813C19.4536 33.6795 27.5252 27.4979 36.5292 25.7801C39.6824 25.1786 45.9152 25.402 49.3567 26.2405ZM26.8128 53.3679C26.8131 58.5111 26.8921 59.5598 27.3467 60.4526C28.8274 63.3618 32.6185 63.6534 34.5552 61.0071C35.1166 60.2402 35.1837 59.5882 35.3015 53.7478L35.4306 47.3312H31.1214H26.8122L26.8128 53.3679ZM50.3046 53.5433C50.3046 59.5106 50.3336 59.8163 50.9972 60.8112C52.3362 62.8196 54.9637 63.4312 56.8715 62.1787C58.3802 61.1883 58.4729 60.7412 58.5873 53.9028L58.6947 47.484L54.4997 47.3991L50.3046 47.3141V53.5433Z" fill="#7E0DD6" />
	</svg>
}